exports.components = {
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-datenschutz-de-js": () => import("./../../../src/pages/datenschutz.de.js" /* webpackChunkName: "component---src-pages-datenschutz-de-js" */),
  "component---src-pages-datenschutz-en-js": () => import("./../../../src/pages/datenschutz.en.js" /* webpackChunkName: "component---src-pages-datenschutz-en-js" */),
  "component---src-pages-impressum-de-js": () => import("./../../../src/pages/impressum.de.js" /* webpackChunkName: "component---src-pages-impressum-de-js" */),
  "component---src-pages-impressum-en-js": () => import("./../../../src/pages/impressum.en.js" /* webpackChunkName: "component---src-pages-impressum-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-kontakt-de-js": () => import("./../../../src/pages/kontakt.de.js" /* webpackChunkName: "component---src-pages-kontakt-de-js" */),
  "component---src-pages-kontakt-en-js": () => import("./../../../src/pages/kontakt.en.js" /* webpackChunkName: "component---src-pages-kontakt-en-js" */),
  "component---src-pages-manufaktur-de-js": () => import("./../../../src/pages/manufaktur.de.js" /* webpackChunkName: "component---src-pages-manufaktur-de-js" */),
  "component---src-pages-manufaktur-en-js": () => import("./../../../src/pages/manufaktur.en.js" /* webpackChunkName: "component---src-pages-manufaktur-en-js" */),
  "component---src-pages-news-de-js": () => import("./../../../src/pages/news.de.js" /* webpackChunkName: "component---src-pages-news-de-js" */),
  "component---src-pages-news-en-js": () => import("./../../../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-schmuck-de-js": () => import("./../../../src/pages/schmuck.de.js" /* webpackChunkName: "component---src-pages-schmuck-de-js" */),
  "component---src-pages-schmuck-en-js": () => import("./../../../src/pages/schmuck.en.js" /* webpackChunkName: "component---src-pages-schmuck-en-js" */),
  "component---src-templates-news-de-js": () => import("./../../../src/templates/News.de.js" /* webpackChunkName: "component---src-templates-news-de-js" */),
  "component---src-templates-news-en-js": () => import("./../../../src/templates/News.en.js" /* webpackChunkName: "component---src-templates-news-en-js" */),
  "component---src-templates-schmuck-de-js": () => import("./../../../src/templates/Schmuck.de.js" /* webpackChunkName: "component---src-templates-schmuck-de-js" */),
  "component---src-templates-schmuck-en-js": () => import("./../../../src/templates/Schmuck.en.js" /* webpackChunkName: "component---src-templates-schmuck-en-js" */)
}

